<template>
  <!--error 404-->
  <div class="error">
    <div class="container">
      <div class="text-center mx-auto">
        <h2>4<span class="diff-color">0</span>4</h2>
        <p class="lead text-gray-800 mb-4">page not found</p>
        <a class="btn back" href="index.html">main page</a>
      </div>
    </div>
  </div>
  <!--error 404-->
</template>

<script>
export default {
  name: "Error404",
};
</script>

<style>
</style>