export default {
  "hello_aquary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا بكم في موقع عقاري"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
  "ChooseAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر العنوان"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاقسام"])},
  "Choose_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر القسم"])},
  "price_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر من"])},
  "price_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر الي"])},
  "enter_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل النص"])},
  "Loading...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل ..."])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استرجاع"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد"])},
  "bestSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاكثر مبيعا"])},
  "featured_properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العقارات المميزة"])},
  "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الكل"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهاتف"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع"])},
  "yourmessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب رسالتك"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "SingleBlog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل المقال"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال"])},
  "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خريطة الموقع"])},
  "Follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابعنا علي السوشيال ميديا"])},
  "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل الان"])},
  "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المميزات"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفاصيل"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفيديوهات"])},
  "plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخطط"])},
  "Started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابدا الان"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاركة"])},
  "ServicesMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الخدمات"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانتقال للخريطة"])},
  "choice_images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الصور"])},
  "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنطقة"])},
  "space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساحة العقار"])},
  "relatedProperties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عقارات ذات صلة"])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
  "Founder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤسس"])},
  "properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العقارات"])},
  "Properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العقارات"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])},
  "makeadvertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل اعلان"])},
  "Ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العربية"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغات"])},
  "Site_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خريطة الموقع"])},
  "En": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانجليزية"])},
  "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فريق العمل"])},
  "Property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل العقار"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم المرور"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكيد رقم امرور"])},
  "Rememberme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تذكرني"])},
  "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتيجة البحث"])},
  "MakeProperty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل اعلان"])},
  "registernow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء حساب"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل دخول"])},
  "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب"])},
  "SingleService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الخدمة"])},
  "Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات"])},
  "Blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المقالات"])},
  "BlogsMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المقالات"])},
  "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الملف الشخصي"])},
  "edit_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل كلمة المرور"])},
  "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ التغيرات"])},
  "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور القديمة"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدية "])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكيد كلمة المرور"])},
  "profile_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تحديث الملف الشخصي"])},
  "no_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد عقارات "])},
  "Message_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم ارسال الرسالة بنجاح"])},
  "favorite_properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العقارات المفضلة"])}
}