<template>
    <section class="contact">
        <div class="container">
            <div class="info-area">
                <div class="row">
                    <div class="col-md-4 col-lg-4">
                        <div class="info-box">
                            <i class="bi bi-envelope"> </i>
                            <h4>{{ $t("email") }}</h4>
                            <a :href="`mailto:${settingsData.email}`">{{
                                settingsData.email
                            }}</a>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                        <div class="info-box">
                            <i class="bi bi-telephone"></i>
                            <h4>{{ $t("phone") }}</h4>
                            <a :href="`tel:${settingsData.phone}`">
                                {{ settingsData.phone }}
                            </a>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                        <div class="info-box">
                            <i class="bi bi-pin-map"></i>
                            <h4>{{ $t("address") }}</h4>
                            <span>{{ settingsData.address }}</span>
                            <br>
                            <span>{{ settingsData.address2 }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <ContactForm />
        </div>
    </section>

    <section class="map">
        <div v-html="settingsData.map"></div>
    </section>
</template>

<script>
// @ is an alias to /src
import ContactForm from "@/components/ContactComponent/contactForm.vue";
import { mapState } from "vuex";

export default {
    name: "HomeView",
    computed: {
        ...mapState(["settingsData"]),
    },
    created() {
        this.$store.dispatch("getContactData");
    },

    components: {
        ContactForm,
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                if (this.$i18n.locale == "en") {
                    document.title =
                        to.meta.title || "Al-qurashi  | Contact us";
                } else {
                    document.title = to.meta.title || " آل قريش | تواصل معنا";
                }
            },
        },
    },
};
</script>
