<template>
    <!--about us-->
    <section class="about">
        <div class="container">
            <div class="content">
                <div class="row">
                    <div class="col d-flex flex-column justify-content-center">
                        <div class="about-text" data-aos="fade-down">
                            <div class="main-title" data-aos="fade-down">
                                <span class="top-title">{{
                                    $t("Founder")
                                }}</span>
                                <h4>{{ founderData.title }}</h4>
                            </div>
                            <div
                                class="founder-description"
                                data-aos="fade-down"
                                data-aos-delay="100"
                                v-html="founderData.description"
                            ></div>

                            <!-- <div class="about-btn" data-aos="fade-down" data-aos-delay="200">
                <div class="outer-btn">
                  <router-link class="btn" :to="{ name: 'About' }"
                    ><span>{{ $t("About") }}</span></router-link
                  >
                </div>
              </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--about us-->
</template>

<script>
export default {
    props: {
        founderData: Array,
    },
};
</script>

<style></style>
